import { useNavigate } from "react-router";
import { BrandPageProps } from "../../models/BrandPage";
import { GiftCard } from "../../models/GiftCard";
import { DescriptionLabel, Section } from 'intersolve.web.componentlib';
import React, { useEffect } from "react";
import { t } from 'i18next';

interface PayOutCardAlreadyRegisteredViewProps extends BrandPageProps {
    giftCard?: GiftCard,
    onNew: () => void,
}

const PayOutCardAlreadyRegistered = (props: PayOutCardAlreadyRegisteredViewProps) => {
    const navigate = useNavigate();
    const tryAgainAction = React.useCallback((e: React.MouseEvent) => {
        props.onNew();
        navigate(`/${props.brand.code}/payout/`);
    }, [navigate, props]);


    useEffect(() => {
        if (!props.giftCard) {
            navigate(`/${props.brand.code}/payout`, { replace: true });
        }
    });

    return (
        props.giftCard ?
            <>
                <Section>
                    <div className="content my-0 alert alert-danger">
                        <div className="panel">
                            <div className="panel-row align-items-start">
                                <div className="panel mb-0">
                                    <div className="icon-cancel-circle__color bg-danger icon-md"></div>
                                </div>
                                <div className="panel">
                                    <div className="label">
                                        {t("payout__alreadyRegistered_title")}
                                    </div>
                                    <div>
                                        {t("payout__alreadyRegistered_description")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>
                <Section className="section">
                    <div className="panel-row">
                        <button className="btn btn-light" type="button" onClick={tryAgainAction}>
                            {t('payout__alreadyRegistered_button')}
                        </button>
                    </div>
                </Section>
            </> :
            <>  </>
    );
};


export default PayOutCardAlreadyRegistered;