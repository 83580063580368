import { DescriptionLabel, Section } from 'intersolve.web.componentlib';
import React from 'react';
import { useNavigate } from 'react-router';
import GiftCardForm from '../../components/GiftCardForm';
import { InformationType } from "../../models/Brand";
import { BrandPageProps } from '../../models/BrandPage';
import { BalanceGiftCard, GiftCard } from '../../models/GiftCard';
import { t } from 'i18next';

interface GiftCardViewProps extends BrandPageProps {
    onNavigate: (giftCard: BalanceGiftCard) => void;
    giftCard?: GiftCard    
}

const BalanceGiftCardViewCheck = (props: GiftCardViewProps) => {

    const navigate = useNavigate();
    const handleGiftCardSuccess = React.useCallback((giftCard: GiftCard) => {
        props.onNavigate(giftCard as BalanceGiftCard);
        navigate(`/${props.brand.code}/balance/amount/`);

    }, [navigate, props]);

    function handleClickScroll(arg0: string, e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void {
        throw new Error('Function not implemented.');
    }

    return (
        <>
            {
                (props.brand?.styling?.informationType === InformationType.ContainerBelow ||
                    props.brand?.styling?.informationType === InformationType.ContainerAbove) &&
                <Section className="content">
                    {props.brand?.styling?.informationType === InformationType.ContainerBelow &&
                            <span onClick={(e) => handleClickScroll('content-1', e)}>{t('balance__explanation_1') }</span>
                    }
                    {props.brand?.styling?.informationType === InformationType.ContainerAbove &&
                        <p>
                                {t('balance__explanation_2')}
                        </p>
                    }
                </Section>
            }
            <GiftCardForm onSuccess={handleGiftCardSuccess} brand={props.brand} url={`/api/GiftCard/`} method={"POST"} showResetUrl/>
            {props.brand?.styling?.informationType === InformationType.ContainerBelow &&
                <Section className="content" >
                    <DescriptionLabel as="h2" display={3} id="hoe-werkt-het">{t('balance__explanation_3')}</DescriptionLabel>
                    <DescriptionLabel as="h3" display={5} muted>{t('balance__GetSaldo')}</DescriptionLabel>
                    <p id="content-1">
                        {t('balance__explanation_4')}
                    </p>
                </Section>
            }
        </>
    );
};

export default BalanceGiftCardViewCheck;