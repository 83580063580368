import React from "react";
import { CreditCard } from "../models/CreditCard";
import { t } from 'i18next';
import { Panel, Currency } from "intersolve.web.componentlib";

export interface CardBalanceProps {
    balance?: number;
    valid?: string | null;
    currency?: string;
    tag_type?: string;
    tag_title?: string | null;
    boxed?: boolean;
    card_title?: string | null;
    balance_title?: string | null;
    creditCard?: CreditCard;
    children?: any;
}

const CardBalance = (props: CardBalanceProps) => {
    let contentClassName = "content";
    // If boxed is true, add "box" to the className
    if (props.boxed) {
        contentClassName += " box bg__light-lighter-5";
    }

    return (
        <>
            <section className="section">
                <div className={contentClassName}>
                    <div className="panel">
                        <div className="label">{props.card_title ? props.card_title : "Kaartnummer"}</div>
                        <div className="bigger">
                            <span dir="ltr"><span className="muted">XXXX XXXX XXXX XXXX </span>{props.creditCard && props.creditCard?.cardNumber?.length >= 3 ? <>{props.creditCard.cardNumber.slice(-3)}</> : <>XXX</>}</span>
                            {props.tag_type && (
                                <span className={`tag tag__${props.tag_type}`}>{props.tag_title}</span>
                            )}
                        </div>
                    </div>
                    {/* show a panel when payoutBalance is not undefined */}
                    {props.balance === undefined && (
                        <Panel>
                            <div className="label">{props.balance_title ? props.balance_title : "Saldo"}</div>
                            <div className="panel-row">
                                <div className="bigger"></div>
                                <div>{props.valid}</div>
                            </div>
                        </Panel>
                    )}
                    {props.balance !== undefined && (
                        <Panel>
                            <div className="label">{props.balance_title ? props.balance_title : "Saldo"}</div>
                            <div className="panel-row">
                                <div className="bigger"><Currency code={props.currency} />
                                    {props.balance !== 0 && (
                                         props.balance !== undefined && (props.balance / 100).toFixed(2))}

                                    {props.balance === 0 && (
                                        <div className="text-center">
                                            <p>{t("payout__no_balance_text")}</p>
                                        </div>
                                    )}

                                </div>
                                <div>{props.valid}</div>
                            </div>
                        </Panel>
                    )}
                </div>
            </section>
        </>
    );
}

export default CardBalance;
