import { Layout, Title } from 'intersolve.web.componentlib';
import { BrandPageProps } from '../../models/BrandPage';

import { useTranslation } from 'react-i18next';
import React from 'react';


interface PayOutLayoutProps extends BrandPageProps {
    children: any;
}
const PayOutLayOutPage = (props: PayOutLayoutProps) => {
    const { i18n } = useTranslation();

    const setLanguage = React.useCallback(async (language: string) => {
        await i18n.changeLanguage(language);
    }, [i18n]);

    const { t } = useTranslation();
    return (
        <Layout
            headerImage={props.brand.styling?.headerImage}
            posterImage={props.brand.styling?.logo}
            addSwoosh={props.brand.styling?.addSwoosh}
            backgroundVariant={props.brand.styling?.backgroundVariant}
            useGradient={props.brand.styling?.useGradient}
            selectedLanguage={i18n.language}
            availableLanguages={props.brand.availableLanguages}
            onLanguageChange={setLanguage}
        >
            <Title title={t('payout__title')} subTitle={t('payout__subtitle')} />
            {props.children}
        </Layout>
    );
};

export default PayOutLayOutPage;