import React, { useCallback } from 'react';
import { Section, handleClickScroll } from 'intersolve.web.componentlib';
import { BrandPageProps } from '../../models/BrandPage';
import { GiftCard, PayoutGiftCard } from "../../models/GiftCard";
import { useNavigate } from 'react-router-dom';
import GiftCardForm from '../../components/GiftCardForm';
import { t } from 'i18next';
import { CardInfo } from '../../models/CardInfo';
import PayoutEndSection from '../../components/payout/PayoutEndSection';

interface PayOutCheckProps extends BrandPageProps {
    onNavigate: (giftCard: PayoutGiftCard, securityCode: string | undefined) => void;
    giftCard?: PayoutGiftCard;
}

const PayOutCheck = (props: PayOutCheckProps) => {
    const navigate = useNavigate();    

    const handleGiftCardSuccess = useCallback((giftCard: GiftCard, cardInfo: CardInfo) => {
        const payoutGiftCard = giftCard as PayoutGiftCard;
        props.onNavigate(payoutGiftCard, cardInfo.securityCode);

        if (payoutGiftCard.payoutBalance.payout != null) {
            if (payoutGiftCard.payoutBalance.payout > 0) {
                navigate(`/${props.brand.code}/payout/amount/`);
            }
            else if (payoutGiftCard.payoutBalance.hasFee) {
                if (payoutGiftCard.payoutBalance.payout === payoutGiftCard.payoutBalance.fee) {
                    navigate(`/${props.brand.code}/payout/nobalance/`);
                }
                else if (payoutGiftCard.payoutBalance.payout < 0) {
                    navigate(`/${props.brand.code}/payout/unsufficientbalance/`);
                }
            }
            else 
                navigate(`/${props.brand.code}/payout/nobalance/`);
        }
       
    }, [navigate, props]);

    return (
        <>
            <Section>
                <div className="content">
                    <span onClick={(e) => handleClickScroll('explanation', e)}>{t('payout__explanation_1')}</span>
                </div>
            </Section>
            <GiftCardForm brand={props.brand} onSuccess={handleGiftCardSuccess} url={`/api/Payout/`} method={"POST"} />
            <PayoutEndSection />
        </>
    );

};

export default PayOutCheck;