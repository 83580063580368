import { Col, Row } from "react-bootstrap";
import idinImage from "../../static/img/iDIN-illustration.png";
import calculateImage from "../../static/img/calculation-illustration.png";
import payoutImage from "../../static/img/payment-illustration.png";
import { Section, DescriptionLabel } from "intersolve.web.componentlib";
import { useTranslation } from "react-i18next";

const PayoutEndSection = () => {
    const { t } = useTranslation();
    return (
        <>
            <Section id="explanation">
                <div className="content">
                    <DescriptionLabel as="h2" display={3} id="hoe-werkt-het">{t('payout__content_title')}</DescriptionLabel>
                    <DescriptionLabel as="h3" display={5} muted>{t('payout__content_subtitle')}</DescriptionLabel>
                </div>
            </Section>
            <Section>
                <div className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={payoutImage}
                                alt="iDIN"
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">{t('payout__content_explainer_1_title')}</h4>
                            <p>
                                {t('payout__content_explainer_1_description')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Section>
            <hr />
            <Section>
                <div className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={idinImage}
                                alt="iDIN"
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">{t('payout__content_explainer_2_title')}</h4>
                            <p>
                                {t('payout__content_explainer_2_description')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Section>
            <hr />
            <Section>
                <div className="content">
                    <Row>
                        <Col sm={12} md={6} className="explainer">
                            <img
                                src={calculateImage}
                                alt="iDIN"
                                className="img-fluid img-whitespace-8"
                            />
                        </Col>
                        <Col sm={12} md={6} className="explainer">
                            <h4 className="display-6">{t('payout__content_explainer_3_title')}</h4>
                            <p>
                                {t('payout__content_explainer_3_description')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Section>
        </>
    );
};

export default PayoutEndSection;