import { Layout } from 'intersolve.web.componentlib';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrandPageProps } from '../../models/BrandPage';
import VisaIssuerStatement from '../../components/Visa/Section/VisaIssuerStatement';

export interface VisaPageProps extends BrandPageProps{
    children: any;
}

const VisaLayout = (props: VisaPageProps) => {
    const { i18n } = useTranslation();

    const setLanguage = React.useCallback(async (language: string) => {
        await i18n.changeLanguage(language);
    }, [i18n]);

    return (
        <Layout
            posterImage={props.brand.styling?.logo}
            headerImage={props.brand.styling?.headerImage}
            headerPosition={'top-left'}
            addSwoosh={true}
            useGradient={true}
            availableLanguages={props.brand.availableLanguages}
            selectedLanguage={i18n.resolvedLanguage}
            onLanguageChange={setLanguage }
        >
            {props.children}
            <VisaIssuerStatement />
        </Layout>
    );
};

export default VisaLayout;