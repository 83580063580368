import { useNavigate } from "react-router";
import { BrandPageProps } from "../../models/BrandPage";
import { GiftCard } from "../../models/GiftCard";
import { DescriptionLabel, Section } from 'intersolve.web.componentlib';
import React, { useEffect } from "react";
import { t } from 'i18next';

interface PayOutSuccessViewProps extends BrandPageProps {
    giftCard?: GiftCard,
    onNew: () => void,
}

const PayOutCardSuccess = (props: PayOutSuccessViewProps) => {
    const navigate = useNavigate();
    const handleStartNewPayOut = React.useCallback((e: React.MouseEvent) => {
        props.onNew();
        navigate(`/${props.brand.code}/payout/`);
    }, [navigate, props]);


    useEffect(() => {
        if (!props.giftCard) {
            navigate(`/${props.brand.code}/payout`, { replace: true });
        }
    });

    return (
        props.giftCard ?
            <>
                <Section>
                    <div className="content my-0 alert alert-success">
                        <div className="panel">
                            <div className="panel-row align-items-start">
                                <div className="panel mb-0">
                                    <div className="icon-check-circle__color bg-success icon-md"></div>
                                </div>
                                <div className="panel">
                                    <div className="label">
                                        {t("payout__success_title")}
                                    </div>
                                    <div>
                                        {t("payout__success_description")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>
                <Section className="section">
                    <div className="panel-row">
                        <button className="btn btn-light" type="button" onClick={handleStartNewPayOut}>
                            {t('payout__newPayment')}
                        </button>
                    </div>
                </Section>
                <Section className="section">
                    <div className="content">
                        <p className="text-muted">{t('payout__leavePage')}</p>
                    </div>
                </Section>
            </> :
            <>  </>
    );
};


export default PayOutCardSuccess;

