import { useNavigate } from "react-router";
import { BrandPageProps } from "../../models/BrandPage";
import { PayoutGiftCard } from "../../models/GiftCard";
import { Section} from 'intersolve.web.componentlib';
import { t } from 'i18next';
import React, { useEffect } from "react";
import { Customer } from "../../models/Customer";
import PayoutPricingTable from "../../components/payout/PayoutPricingTable";

interface PayOutFailedViewProps extends BrandPageProps {
    giftCard?: PayoutGiftCard,
    customer?: Customer;
    onRetry:() => Promise <void>;
}
const PayOutCardUnsufficientBalance = (props: PayOutFailedViewProps) => {
    const navigate = useNavigate();
    const handleRetryNewPayOut = React.useCallback(async (e: React.MouseEvent) => {
       await props.onRetry();
        navigate(`/${props.brand.code}/payout/`);
    }, [navigate, props]);

    useEffect(() => {
        if (!props.giftCard) {
            navigate(`/${props.brand.code}/payout`, { replace: true });
        }
    });

    return (
        props.giftCard ?
            <>
                <Section className="section">
                    <div className="content">
                        <p>
                            {t('payout__failedErorMessage')}
                        </p>

                        <PayoutPricingTable giftCard={props.giftCard} />
                    </div>
                   
                        <div className="panel-row">
                            <button className="btn btn-light" type="button" onClick={handleRetryNewPayOut}>
                                {t('generic__retryButtonLabel')}
                            </button>
                        </div>
                   
                </Section>
            </> :
            <>
            </>
    );

};
export default PayOutCardUnsufficientBalance;