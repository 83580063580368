import { useNavigate } from "react-router-dom";
import { Section, LoaderButton, IBANInput, RetryButton, DefaultInput,Panel } from 'intersolve.web.componentlib';
import CustomerInfo from "../../components/CustomerInfo";
import { InformationType } from "../../models/Brand";
import { BrandPageProps } from "../../models/BrandPage";
import { PayoutGiftCard } from "../../models/GiftCard";
import React, { useEffect, useRef, useState } from "react";
import { Customer } from "../../models/Customer";
import { Form, Alert } from "react-bootstrap";
import { t } from 'i18next';
import PayoutPricingTable from "../../components/payout/PayoutPricingTable";

interface PayOutVerificationViewProps extends BrandPageProps {
    giftCard?: PayoutGiftCard,
    customer?: Customer,
    securityCode?: string,
}

const PayOutCardConfirm = (props: PayOutVerificationViewProps) => {
    const [messageKey, setMessageKey] = useState("");
    const [error, setError] = useState<boolean>(false);
    const [alert, setAlert] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [bankAccountCardNumber, setBankAccountCardNumber] = useState<string>();

    const navigate = useNavigate();
    const [containBankNumber, setContainBankNumber] = useState<boolean>(true);

    const onChangeBankAccountNumber = React.useCallback((value: string | undefined) => {
        setBankAccountCardNumber(value);
        setContainBankNumber(value !== undefined && value.trim().length > 0);
    }, [setBankAccountCardNumber, setContainBankNumber]);
    const customerBankAccount = useRef<HTMLInputElement>(null);
    
    const handleRetryCardInfo = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setError(false);
        setAlert(false);
    }, [setError, setAlert]);

    async function CreateBankAccount(brandCode, bankAccountData) {
        const response = await fetch(`/api/payout/${brandCode}/payout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(bankAccountData),
        });       
        return response;
    }

    const handleCreateBankAccount = React.useCallback(async () => {
        setLoading(true);
        const brandCode = props.brand.code;
        const bankAccountData = {
            accountNumber: bankAccountCardNumber,
            securityCode: props.securityCode,
            cardNumber: props.giftCard?.code
        };
       
        try {
            const response = await CreateBankAccount(brandCode, bankAccountData);
            if (response.ok) {
                navigate(`/${props.brand.code}/payout/success/`);
            }
            else {               
              
                if (response.status === 400) {
                    response.json().then((json) => {
                        if (json.code === "BANK_ACCOUNT_VERIFICATION_FAILED") {
                            setAlert(true);
                            setMessageKey("payout__bank_verification_failed");
                        }
                        else if (json.code === "BANK_ACCOUNT_CREATION_FAILED") {
                            setAlert(true);
                            setMessageKey("payout__bank_creation_failed");
                        }
                    });
                }               
            }
        } catch(err) {
            setError(true);
            console.log(err)
            navigate(`/${props.brand.code}/payout/failed/`);
        }
        finally {
            setLoading(false);
        }
    }, [props, bankAccountCardNumber, navigate]);

    useEffect(() => {
        if (!props.giftCard) {
            navigate(`/${props.brand.code}/payout`, { replace: true });
        }
    });

    return (
        props.giftCard ?
        <>
            <Section className="content">
                <p>{t('payout__instructions')}</p>
                <Form>
                    <IBANInput label={t('payout__bankAccount')} placeholder="NL00 BANK 0000 0000 0000 00" onChange={onChangeBankAccountNumber} inputRef={customerBankAccount} addToolTip
                        toolTip={{
                            enabled: props.brand?.styling?.informationType === InformationType.Tooltip, type: "icon", props: { text: t('payout__payOutCardNumberToolTip_1'), placement: "right", className: "custom-tooltip" }
                        }}
                    />
                    <DefaultInput disabled={true} label={t('payout__customerName')} placeholder={`${props.customer?.individual?.initials ?? ""} ${props.customer?.individual?.middleName ?? ""} ${props.customer?.individual?.lastName ?? ""}`.replace("  ", " ")} addToolTip onChange={() => { }}
                    toolTip={{
                        enabled: props.brand?.styling?.informationType === InformationType.Tooltip, type: "icon", props: { text: t('payout__payOutCardNumberToolTip_2'), placement: "right", className: "custom-tooltip" }
                    }}
                    />
                </Form>

            </Section>

            <Section className="content">
                <div className="panel mb-3">
                    <div className="label"> {t('payout__titlepayOutToCustomer')} </div>
                </div>
                {props.customer &&
                    <CustomerInfo customer={props.customer} />
                }
            </Section>

            <Section className="section">
                <div className="content">
                    <div className="form-label"> {t('payout__lablePayOutVerification')}</div>
                </div>
                <div className="panel">
                    <PayoutPricingTable giftCard={props.giftCard} />
                </div>
            </Section>

            <Section className="content">
                <Panel>
                    {error &&
                        <Alert key="danger" variant="danger">
                            {t('payout__error_invalidBank')}
                        </Alert>
                    }
                    {alert &&
                        <Alert key="danger" variant="danger">
                            {t(messageKey)}
                        </Alert>
                    }
                </Panel>
                <div className="content">
                    {error || alert?
                        <RetryButton type="button" onClick={handleRetryCardInfo}>
                            {t('generic__buttonRetry')}
                        </RetryButton>
                        :
                        <LoaderButton disabled={!containBankNumber} loading={loading} onClick={handleCreateBankAccount}>
                            {loading ? t('payout__loadingButtonLabel') : t('payout__checkAccount')}
                        </LoaderButton>
                    }                   
                </div>
            </Section>
        </> : <></>
    );
};

export default PayOutCardConfirm;