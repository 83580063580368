﻿import React, { ChangeEvent, RefObject, useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { ToolTip } from '../CustomTooltip';
import { ToolTipProps } from '../CustomTooltip/ToolTip';
import { InputProps } from '.';

interface IBANInputprops extends InputProps {
    label: string,
    onChange: (value: string | undefined) => void,
    inputRef: RefObject<HTMLInputElement>;  
    addToolTip?: boolean;
    toolTip?: {
        props: ToolTipProps;
        enabled: boolean;
        type: "icon" | "subtext";
    };
    placeholder?: string,
}

const LEFT_TO_RIGHT_MARK = "‎\u200e"; 

const IBANInput: React.FC<IBANInputprops> = (props: IBANInputprops) => {
    const [cardInput, setCardInput] = useState<string>();
    const [displayInputValue, setDisplayInputValue] = useState<string>();

    const onInputValueChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const newCardInput = event.target.value.replace(LEFT_TO_RIGHT_MARK, "");
        setCardInput(newCardInput.replace(/\s/g, ''));       
        setDisplayInputValue(newCardInput
            .replace(/\s/gi, '')
            .replace(/(.{4})/g, '$1 ').trim()
            .toUpperCase());

    }, [setCardInput, setDisplayInputValue]);

    useEffect(() => {
        props.onChange(cardInput);
    }, [cardInput]);

    return (
        <Form.Group className={`form-group ${props.className}`}>        

            {props.label &&
                <React.Fragment>
                    <Form.Label>{props.label}</Form.Label>

                    {props.toolTip?.enabled && props.toolTip?.type === "icon" &&
                        <ToolTip {...props.toolTip.props}></ToolTip>
                    }
                </React.Fragment>
            }
            <InputGroup>
                <Form.Control placeholder={props.placeholder} name="InputValue"
                    className={
                        props.error ? "InputValue large error" : "InputValue large"
                    }
                    value={displayInputValue ? LEFT_TO_RIGHT_MARK + displayInputValue : displayInputValue}
                    required
                    type="text" 
                    inputMode="text"
                    onChange={onInputValueChange}
                    ref={props.inputRef}
                    disabled={props.disabled}
                />
                
            </InputGroup>
            {props.toolTip?.enabled && props.toolTip?.type == "subtext" && <div>{props.toolTip.props.text}</div>}
        </Form.Group>
    );
};

export default IBANInput;